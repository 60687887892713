import React from 'react';

const BanModal = ({ isFlagging, reason, reasonCreatedAt, reasonActor, flagUntil, reasonDescription }) => {
  const renderTitle = () => {
    return isFlagging ? "User Flagged for Review" : "Banned User";
  };

  return (
    <div className="reveal-modal middling" id="ban-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png" alt="Close modal"/>
          </a>
        </div>
        <div className="modal-content middling">
          <h4>{renderTitle()}</h4>
          <p>
            <strong>Reason:</strong> {reason}
            <br />
            <strong>Time:</strong> {reasonCreatedAt}
            <br />
            <strong>Description:</strong> {reasonDescription}
            <br />
            {isFlagging && (
              <>
                <strong>Flag Until:</strong> {flagUntil}
                <br />
              </>
            )}
            <strong>By:</strong> {reasonActor}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BanModal;