var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';
import FlagModal from '../AdminAuthorStatsTable/FlagModal';
import BanModal from '../AdminAuthorStatsTable/BanModal';

const Main = createReactClass({
  getInitialState: function() {
    return {
      flagStatus: this.props.user.flag,
    }
  },

  handleSetFlag: function(flag) {
    var self = this;
    $.ajax({
      type: "PUT",
      url: self.props.user.updateFlagPath,
      data: { id: self.props.user.id, user: {flag: flag} },
      success: function(data) {
        self.setState({flagStatus: data.flag});
        self.closeModal();
        $("#editor-article-rating-trigger").trigger('reload');
      }
    });
  },

  handleFlagClick: function() {
    if (this.props.user.flag == 'Banned' || this.props.user.flag === 'Flagged') {
      Modal.open({
        children: <BanModal
          reason={this.props.user.reason}
          reasonCreatedAt={this.props.user.reason_created_at}
          reasonActor={this.props.user.reason_actor}
          isFlagging={this.props.user.flag === 'Flagged'}
          flagUntil={this.props.user.flagUntil}
          reasonDescription={this.props.user.reason_description}
        />
      })
    } else if (this.props.current_user.canEdit) {
      Modal.open({
        children: <FlagModal
          handleSetFlag={this.handleSetFlag}
          flag={this.state.flagStatus}
          authorPath={this.props.user.authorPath}
          updateFlagPath={this.props.user.updateFlagPath}
        />
      });
    }
  },

  renderFlag: function() {
    if (this.state.flagStatus) {
      return (
        <td>
          <div style={this.handleCursor()} onClick={ this.handleFlagClick } className="nowrap underline">{ this.state.flagStatus }</div>
        </td>
      )
    } else {
      return (
        <td>
          <i style={this.handleCursor()} onClick={ this.handleFlagClick } className="fa fa-flag fa-lg"/>
        </td>
      )
    }
  },

  handleCursor: function() {
    if (this.props.current_user.canEdit) {
      return (
        {cursor: 'pointer'}
      )
    } else {
      return (
        {cursor: 'default'}
      )
    }
  },

  closeModal: function() {
    Modal.close();
  },

  shouldRenderChannels: function() {
    return this.props.user.channels.length > 0
  },

  renderChannelContents: function() {
    return (
      this.props.user.channels.map((channel) => {
        return (
          (
            <div key={channel.url}>
              <a href={channel.url} target="_blank">{channel.title}</a>
            </div>
          )
        )}
      )
    )
  },

  renderChannels: function() {
    if (this.shouldRenderChannels()) {
      return (
        <tr>
          <td>
            <string>Channels:</string>
          </td>
          <td>
            {this.renderChannelContents()}
          </td>
        </tr>
      )
    }
  },

  render: function (){
    return (
      <div className="large-6 columns">
        <h4>{this.props.user.humanized_type}</h4>
        <table className="user-info table-wrapper" style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                <strong>Primary Affiliation:</strong>
              </td>
              <td>
                {this.props.user.affiliation}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Suggested Tier:</strong>
              </td>
              {this.renderFlag()}
            </tr>
            <tr>
              <td>
                <strong>On Demand Reviews</strong>
              </td>
              <td>
                {this.props.user.onDemand}
              </td>
            </tr>
            {this.renderChannels()}
          </tbody>
        </table>
        {this.props.user.isPublicProfileDeleted && <i>Public profile has been removed</i>}
      </div>
    )
  }
});

export default Main;